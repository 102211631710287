import PropTypes from 'prop-types';
import pathOr from 'ramda.pathor';
import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from 'contexts/AppContext';

import { getProfileInfo, getUser } from 'services/userService';
import { parseDisplayName, parseLegalName } from 'utils';
import { getLocalAuth, updateLocalAuthUser } from 'utils/auth';

import { COOKIE_VERIFICATION_EMAIL_REQUESTED } from 'components/Organisms/BannerIsVerified';
import COMMERCIAL_PROFILE_IMAGE from 'images/avatar-commercial.png';
import RESIDENT_PROFILE_IMAGE from 'images/avatar-resident.png';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { readCookie } from 'utils/cookie';
import { isDesiredUnitAvailable } from '../services/unitsService';

export const UserContext = React.createContext();

const DEFAULT_USER_PROFILE = {
  residentProfileImage: RESIDENT_PROFILE_IMAGE,
  commercialProfileImage: COMMERCIAL_PROFILE_IMAGE,
  applicantId: '',
  customerId: '',
  householdId: '',
  applicationId: '',
  leaseId: '',
  unitId: '',
  portalUserId: '',
  firstName: '',
  lastName: '',
  displayName: '',
  legalName: '',
  phoneNumber: '',
  emailAddress: '',
  birthday: '',
  isProspect: false,
  isApplicant: false,
  isResident: false,
  isGuarantor: false,
  isPriorResident: false,
  isCommercial: false,
  needsRecert: false,
  recertification: {},
  householdType: '',
  leaseReadyForSigning: null,
  areFeesApplied: false,
  areFeesPaid: false,
  prospectPreferences: {},
};

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  const ldClient = useLDClient();
  const { organizationId, propertyId, authenticationPortal } =
    useContext(AppContext);
  const localAuth = getLocalAuth();
  const isVerificationEmailRequested = readCookie(
    COOKIE_VERIFICATION_EMAIL_REQUESTED,
  );
  const {
    marketingInfo: { propertyClass: propertyType },
  } = useContext(AppContext);
  const [authUser, setAuthUser] = useState(localAuth.authUser);
  const [userProfile, setUserProfile] = useState(DEFAULT_USER_PROFILE);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const prospectId = authUser?.prospectId?.length ? authUser.prospectId : null;
  const householdProspectId = userProfile?.householdProspectId?.length
    ? userProfile.householdProspectId
    : null;
  const headOfHouseholdProspectId = prospectId ?? householdProspectId;
  const areApplicationFeesPaid = userProfile?.areFeesPaid ?? false;
  const applicationHouseholdType = userProfile?.householdType;
  const areApplicationFeesUnpaid = !areApplicationFeesPaid;
  const { prospectPreferences } = userProfile;

  const toggleHasUpdate = () => {
    setHasUpdate(!hasUpdate);
  };
  const updateAuthUser = (authUserUpdate) =>
    setAuthUser((prevAuthUser) => ({ ...prevAuthUser, ...authUserUpdate }));

  useEffect(() => {
    if (authUser?.id && organizationId && propertyId) {
      ldClient.identify({
        key: authUser.id,
        custom: { organizationId, propertyId, propertyType },
      });
    }
  }, [authUser.id, ldClient, organizationId, propertyId, propertyType]);

  useEffect(() => {
    const getPortalUser = async () => {
      const portalUser = await getUser({
        organizationId,
        propertyId,
        id: authUser.id,
      });

      if (portalUser.isVerified !== authUser.isVerified) {
        updateLocalAuthUser({ isVerified: portalUser.isVerified });
        setAuthUser(portalUser);
      }
    };

    if (authenticationPortal && authUser?.id) {
      getPortalUser();
    }
  }, [
    authUser,
    organizationId,
    propertyId,
    isVerificationEmailRequested,
    authenticationPortal,
  ]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      setIsLoading(true);
      if (authUser?.id) {
        const profileInfo = await getProfileInfo({
          organizationId,
          propertyId,
          portalUserId: authUser.id,
        });

        const customerStatus = pathOr('', ['customerStatus'], profileInfo);
        const customerStatusObj = {
          isProspect: customerStatus === '',
          isApplicant: customerStatus === 'Applicant',
          isResident: customerStatus === 'Current Resident',
          isPriorResident: customerStatus === 'Prior Resident',
        };

        const isPrimaryProspect = pathOr(
          false,
          ['isPrimaryProspect'],
          profileInfo,
        );

        const isFinanciallyResponsible = pathOr(
          false,
          ['isFinanciallyResponsible'],
          profileInfo,
        );

        const householdType = pathOr('', ['householdType'], profileInfo);
        const isUnitAvailable = await isDesiredUnitAvailable(
          organizationId,
          propertyId,
          profileInfo?.unitId,
        );

        setUserProfile({
          ...DEFAULT_USER_PROFILE,
          ...profileInfo,
          ...customerStatusObj,
          displayName: parseDisplayName(profileInfo),
          legalName: parseLegalName(profileInfo),
          isPrimaryProspect,
          isFinanciallyResponsible,
          householdType,
          isUnitAvailable,
        });
      }
      setIsLoading(false);
    };

    fetchUserProfile();
  }, [
    authUser.id,
    organizationId,
    propertyId,
    authenticationPortal,
    hasUpdate,
  ]);

  const userContextValues = {
    portalUserId: userProfile?.portalUserId ?? null,
    userProfile,
    applicationHouseholdType,
    setUserProfile,
    authUser,
    prospectId: headOfHouseholdProspectId,
    updateAuthUser,
    toggleHasUpdate,
    hasUpdate,
    isLoading,
    areApplicationFeesPaid,
    areApplicationFeesUnpaid,
    prospectPreferences,
  };

  return (
    <UserContext.Provider value={userContextValues}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { UserContext, UserContextProvider };
