import { useQuery } from 'react-query';
import useAbortController from 'hooks/useAbortController';
import { getApplicantTypes } from 'services/applicantTypes';

const useApplicantTypesQuery = ({ options } = {}) => {
  const abortControllerOptions = useAbortController();
  const queryKey = ['getApplicantTypes'];

  return useQuery(queryKey, () => getApplicantTypes(abortControllerOptions), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnSettled: false,
    retry: false,
    ...options,
  });
};

export default useApplicantTypesQuery;
