import { get } from 'utils/api';
export const getApplicantTypes = async (options = {}) => {
  const url = `/applicantTypes`;
  const response = await get(url, options);

  if (response.error) {
    return response.error;
  }

  return response;
};
